import React from "react";
import './footer.scss';
import '../../app.scss';
import { tradingLink, loginLink } from "../../constants/variables";
import Phones from './assets/footer-phones.webp';
import Logo from '../../assets/logo-tradingFx.png';
import { useSelector } from 'react-redux';
// import google from './assets/btn-google-play-high-res.png';
// import apple from './assets/btn-app-store.svg';
import langTokens from '../../languages/en.json';   // for new implementation 



const Footer = () => {
    // const langTokens = useSelector((state) => state.language.data);
    const selectedLang = useSelector((state) => state.language.selected);
    const navLinks = [
        { inner: langTokens.HEAD_NAVLINK1, href: "/trading-platforms" },
        { inner: langTokens.HEAD_NAVLINK7, href: "/contract-specification" },
        { inner: langTokens.HEAD_NAVLINK10, href: "/conditions" },
        // { inner: langTokens.HEAD_NAVLINK11, href: "/deposit-withdrawals" },
        { inner: langTokens.HEAD_NAVLINK2, href: "/market-news" },
        { inner: langTokens.HEAD_NAVLINK8, href: "/calendar" },
        { inner: langTokens.HEAD_NAVLINK3, href: "/cfd" },
        { inner: langTokens.HEAD_NAVLINK4, href: "/crypto" },
        { inner: langTokens.HEAD_NAVLINK5, href: "/forex" },
        { inner: langTokens.HEAD_NAVLINK12, href: "/commodities" },
        // { inner: langTokens.HEAD_NAVLINK6, href: "/contacts" }
    ]
    return (
        <>
            <div className="footer-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center">
                            <div className="footer-block__text">
                                <h2>{langTokens.MAIN_FOOTER_BANNER_TITTLE}</h2>
                                <p>{langTokens.MAIN_FOOTER_BANNER_TEXT_1}<br /> {langTokens.MAIN_FOOTER_BANNER_TEXT_2} {langTokens.MAIN_FOOTER_BANNER_TEXT_3} </p>
                                <a href={loginLink} className={`footer-block__btn ${selectedLang === 'ru' ? "lg-btn" : ""}  md-btn `}>
                                    {langTokens.BTN_TRY}
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 footer-block__img d-sm-none d-md-block">
                            <img alt="" src={Phones} height="339px" width="439px" />
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container">
                    <div className="row overall-footer-top">
                        <div className="col-lg-2 logo_auxiliary">
                            <img alt="FX-Digital Trading" width="150" height="150" src={Logo} />
                        </div>
                        <div className="col-lg-10">
                            <div className="footer__menu">
                                <ul className="footer__link list-inline m-0 desk_ul">
                                    {navLinks.map((i, index) =>
                                        <li key={index}><a href={i.href}>{i.inner}</a></li>
                                    )}
                                    {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/Terms&Conditions.pdf`} target="_blank" rel="noreferrer">{langTokens.MAIN_FOOTER_DOCUMENT_TERMS}</a></li> */}
                                    {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/PrivacyPolicy.pdf`} target="_blank" rel="noreferrer">{langTokens.MAIN_FOOTER_DOCUMENT_PRIVAT}</a></li>s */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr className="d-none d-lg-block" />
                    <div className="row">
                        <div className="col-md-9">
                            <ul className="footer__link list-inline m-0 desk_ul main_ul footer__link__title">
                                <li><a>{langTokens.FOOTER_TITTLE_LINK1}</a></li>
                                <li><a>{langTokens.FOOTER_TITTLE_LINK2}</a></li>
                                <li><a>{langTokens.FOOTER_TITTLE_LINK3}</a></li>
                                {/* <li><a>{langTokens.FOOTER_TITTLE_LINK4}</a></li> */}
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/trading-platforms">{langTokens.HEAD_NAVLINK1}</a></li>
                                <li><a href="/market-news">{langTokens.HEAD_NAVLINK2}</a></li>
                                <li><a href="/cfd">{langTokens.HEAD_NAVLINK3}</a></li>
                                <li></li>
                                {/* <li><a href="/contacts">{langTokens.HEAD_NAVLINK6}</a></li> */}

                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/contract-specification">{langTokens.HEAD_NAVLINK7}</a></li>
                                <li><a href="/calendar">{langTokens.HEAD_NAVLINK8}</a></li>
                                <li><a href="/forex">{langTokens.HEAD_NAVLINK5}</a></li>
                                {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/T&C.pdf`}>{langTokens.MAIN_FOOTER_DOCUMENT_TERMS}</a></li> */}
                                <li></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                {/* <li><a href="/conditions">{langTokens.HEAD_NAVLINK10}</a></li> */}
                                <li></li>
                                <li></li>
                                <li><a href="/crypto">{langTokens.HEAD_NAVLINK4}</a></li>
                                {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/Privacy Policy.pdf`}>{langTokens.MAIN_FOOTER_DOCUMENT_PRIVAT}</a></li> */}
                                <li></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                {/* <li><a href="/deposit-withdrawals">{langTokens.HEAD_NAVLINK11}</a></li> */}
                                <li></li>
                                <li></li>
                                <li><a href="/commodities">{langTokens.HEAD_NAVLINK12}</a></li>
                                <li></li>
                                {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/Risk Disclosure.pdf`}>Risk Disclosure</a></li> */}
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li></li>
                                <li></li>
                                <li></li>
                                {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/AML.pdf`}>AML</a></li> */}
                                <li></li>
                            </ul>
                        </div>
                        <div className="col-md-3 download_group">
                            {/* <a href=""><img src={google} alt="" className="download_group__btn" width={"135px"} height={"40px"} /></a> */}
                            {/* <a href="#"><img src={apple} alt="" width={"135px"} height={"40px"} /></a> */}
                        </div>
                    </div>
                    <hr className="d-none d-lg-block" />
                    <div className="risk"> <b>Legal and regulatory information:</b> This website is controlled and operated by FX-Digital from its head offices within the United Kingdom. FX-Digital is a trading name of Equity Capital Limited. Equity Capital is incorporated with limited liability under the laws of England and Wales, under company number 07216039, with its registered address at 2 London Wall Place, London, EC2Y 5AU, United Kingdom. Equity Capital Limited is authorized and regulated in the UK by the Financial Conduct Authority with firm reference number 528328.
                      In accordance with the FCA rules, FX-Digital products are directed at professional and eligible counterparty clients only. Some products mentioned on this website may not be suitable for you and may not be available in all jurisdictions.
                    </div>
                    <div className="risk">
                    <b>Risk warning:</b> Contracts for difference (‘CFDs’) is a complex financial product, with speculative character, the trading of which involves significant risks of loss of capital. Trading CFDs, which is a marginal product, may result in the loss of your entire balance. Remember that leverage in CFDs can work both to your advantage and disadvantage. CFDs traders do not own, or have any rights to, the underlying assets. Trading CFDs is not appropriate for all investors. Past performance does not constitute a reliable indicator of future results. We recommend you seek professional advice before investing.
                    <div className="copyBlock">
                      <p className="m-md-none">{langTokens.MAIN_FOOTER_COPYRIGHT}</p>
                      <a className="m-md-none" href="/legal-documents">Legal Documents</a>
                    </div>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default Footer;