import React from "react";
import "./accountPage.scss";




export default function AccountPage() {
  let arrBody =[
    {account: "Spreads", basic: "Regular Spreads", gold: "Up to 10% Discount", platinum: "Up to 20% Discount", vip: "Up to 30% Discount", diamond: "Up to 30% Discount"},
    {account: "Market Review", basic: "Not included", gold: "Monthly Review", platinum: "Weekly Review", vip: "Daily Review", diamond: "Daily Review"},
    {account: "Withdrawals Without Fees", basic: "One Time Offer", gold: "1 Free withdrawal each month", platinum: "4 Free withdrawal each month", vip: "No widthrawal fee", diamond: "No widthrawal fee"},
    {account: "Daily Analysis", basic: "-", gold: "Via email or with the Account Manager", platinum: "Via email or with the Account Manager", vip: "Via email or with the Account Manager", diamond: "Via email or with the Account Manager"},
    {account: "Assets Access", basic: "-", gold: "-", platinum: "Full Access to All Assets", vip: "Full Access to All Assets", diamond: "Full Access to All Assets"},
    {account: "SWAP", basic: "Standart SWAP", gold: "Up to 10% SWAP Discount", platinum: "Up to 20% SWAP Discount", vip: "Up to 25% SWAP Discount", diamond: "No SWAP"},
  ]
 return(
  <div className="accountPage container">
    <h1 className='indent__title_auxilary'>Accounts</h1>
    <div className="tableBlock">
      <table>
        <thead>
          <tr>
            <th>Account <br/> types</th>
            <th>Basic <br/> $250</th>
            <th>Gold <br/> $25,000</th>
            <th>Platinum <br/> $50,000</th>
            <th>Vip <br/> $150,000 <br/> and above</th>
            <th>Diamond <br/> $250,000</th>
          </tr>
        </thead>
        <tbody>
          {arrBody.map((i, index) => 
          <tr>
            <td>{i.account}</td>
            <td>{i.basic}</td>
            <td>{i.gold}</td>
            <td>{i.platinum}</td>
            <td>{i.vip}</td>
            <td>{i.diamond}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
    
  </div>
 ) 
}