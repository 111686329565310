import React from "react";
import { useState, useEffect, useRef } from "react";
import './riskBanner.scss'
import topSign from './assets/top_scroll.svg'
import { useSelector } from 'react-redux';



export default function RiskBanner (){
  const[risk, setRisk] = useState('false');
  const[banner, setBanner] = useState(false);
  const bannerRef = useRef();
  const langTokens = useSelector((state) => state.language.data);


  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
        const entry = entries[0];
        setBanner(entry.isIntersecting);
    });          
    observer.observe(bannerRef.current);  
});

  return (
    <section className="risk_banner" ref={bannerRef}>
      <div className={`sticky-bottom ${banner ? 'inactive':''} `}>
      <button className="risk_btn" onClick={()=>setRisk(!risk)}>
          {langTokens.MAIN_RISK_BANNER_BTN}:   <img src={topSign} alt="" width="11px" height="5px"/>
        </button>
      <div className={`${risk ? '': 'active'} text `}>
      <span>
      Risk warning: Contracts for difference (‘CFDs’) is a complex financial product, with speculative character, the trading of which involves significant risks of loss of capital. Trading CFDs, which is a marginal product, may result in the loss of your entire balance. Remember that leverage in CFDs can work both to your advantage and disadvantage. CFDs traders do not own, or have any rights to, the underlying assets. Trading CFDs is not appropriate for all investors. Past performance does not constitute a reliable indicator of future results. We recommend you seek professional advice before investing.
      </span>
      </div>
      </div>
    </section>
)}