// export const tradingLink = "https://trading.fx-digital.com"    // old implementation
export const tradingLink = "https://client.fx-digital.com/sign-in"      // new implementation
export const loginLink = "https://client.fx-digital.com/sign-up"
// export const navLinks = [
//     { inner: langTokens.HEAD_NAVLINK1, href: "/trading-platforms" },
//     { inner: langTokens.HEAD_NAVLINK2, href: "/market-news" },
//     { inner: langTokens.HEAD_NAVLINK3, href: "/cfd" },
//     { inner: langTokens.HEAD_NAVLINK4, href: "/crypto"},
//     { inner: langTokens.HEAD_NAVLINK5, href: "/forex"},
//     { inner: langTokens.HEAD_NAVLINK6, href: "/contacts"}
// ]
export const newsImageRequest = "https://trading.fx-digital.com/endpoint/v1/storage/";